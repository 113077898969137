<template>
  <nav class="flex items-center gap-md">
    <template
      v-for="(item, index) in items"
      :key="index"
    >
      <UiBreadcrumbsLink
        v-bind="item"
        :last="index === lastIndex"
      />
      <UiIcon
        v-if="index !== lastIndex"
        name="chevron-right"
        class="size-4 text-gray-300"
      />
    </template>
  </nav>
</template>

<script lang="ts" setup>
import type { BreadcrumbLink } from './Link.vue'

const props = defineProps<{
  items: BreadcrumbLink[]
}>()

const lastIndex = computed(() => props.items.length - 1)
</script>
