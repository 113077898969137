<template>
  <NuxtLink
    class="rounded-sm text-sm font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500"
    :class="{
      'p-xs': !!icon,
      'px-md py-xs': !!label,
      'hover:bg-gray-50 hover:transition-colors hover:duration-200': !current,
      'bg-brand-50': current,
    }"
    v-bind="linkProps"
  >
    <UiIcon
      v-if="icon"
      :name="icon"
      class="block size-5 text-gray-500"
      :class="{
        'text-gray-500': !current,
        'text-brand-700': current,
      }"
    />
    <span
      v-if="label"
      :class="{
        'text-gray-600': !current,
        'text-brand-700': current,
      }"
    >
      {{ label }}
    </span>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { NuxtLinkProps } from '#app'

export type BreadcrumbLink = Omit<NuxtLinkProps, 'custom' | 'activeClass' | 'exactActiveClass'> & {
  icon?: string
  label?: string
  current?: boolean
}

const { icon, label, ...linkProps } = defineProps<BreadcrumbLink>()
</script>
